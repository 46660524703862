<script setup lang="ts">
import {shallowRef} from 'vue';
import {useCustomizerStore} from '@/stores/customizer';
import {sidebarDevelopment} from './sidebarDefault';

const customizer = useCustomizerStore();
const sidebarMenuDevelopment = shallowRef(sidebarDevelopment);
// Selection related
const userStore = useUserStore();
const {selectedWorkspace} = storeToRefs(userStore);
</script>

<template>
  <v-navigation-drawer
      left
      v-model="customizer.Sidebar_drawer"
      elevation="0"
      rail-width="75"
      :mobile-breakpoint="960"
      app
      class="leftSidebar d-print-none"
      :rail="customizer.mini_sidebar"
      :touchless="true"
      expand-on-hover width="270"
      @mouseenter="customizer.SET_MINI_HOVER(true)"
      @mouseleave="customizer.SET_MINI_HOVER(false)"
  >
    <!---Logo part -->
    <v-locale-provider v-if="customizer.setRTLLayout" rtl>
      <div class="pa-5">
        <LcFullLogoRtlLogo/>
      </div>
    </v-locale-provider>
    <v-locale-provider v-else>
      <div class="mt-6" :class="customizer.getMiniSidebar && !customizer.getMiniHover ? 'ml-4' : 'ml-6' ">
        <LcFullLogo/>
      </div>
    </v-locale-provider>

    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <perfect-scrollbar class="scrollnavbar">
      <v-list class="pa-6" density="compact">
        <!---Menu Loop -->
        <template v-for="(item, i) in customizer.getSidebarItems">
          <!---Item Sub Header -->
          <LcFullVerticalSidebarNavGroup :item="item" v-if="item.header" :key="item.title"/>
          <!---If Has Child -->
          <LcFullVerticalSidebarNavCollapse class="leftPadding" :item="item" :level="0" v-else-if="item.children"/>
          <!---Single Item-->
          <LcFullVerticalSidebarNavItem :item="item" v-else class="leftPadding"/>
          <!---End Single Item-->
          <v-list-item
              v-if="selectedWorkspace.id === '64c6e02b-d11b-4381-b9e6-1dd44c28114e' && item.to === '/analytics/dashboard'"
              class="leftPadding mb-1"
              href="https://analytics.integrahub.de/dashboards/f/fe971ao7n6txca/?orgId=1"
              rounded
              target="_blank"
          >
            <template v-slot:prepend>
              <img src="~/assets/logos/matomo.png" alt="matomo" height="20" width="20">

            </template>
            <v-list-item-title>Matomo</v-list-item-title>
            <template v-slot:append>
              <ExternalLinkIcon size="20"
                                stroke-width="1.5"
                                class="iconClass"/>
            </template>
          </v-list-item>
          <v-list-item
              v-if="selectedWorkspace.id === '64c6e02b-d11b-4381-b9e6-1dd44c28114e' && item.to === '/analytics/dashboard'"
              class="leftPadding mb-1"
              href="https://analytics.integrahub.de/dashboards/f/ce97g1r3ul6v4d/?orgId=1"
              rounded
              target="_blank"
          >
            <template v-slot:prepend>
              <BrandMetaIcon size="20"
                             color="blue"
                             stroke-width="1.5"
              />
            </template>
            <v-list-item-title>Meta</v-list-item-title>
            <template v-slot:append>
              <ExternalLinkIcon size="20"
                                stroke-width="1.5"
                                class="iconClass"/>
            </template>
          </v-list-item>
        </template>
        <!---Menu Loop -->
        <template v-for="(item, i) in sidebarMenuDevelopment" v-if="customizer.getDevelopmentSidebarItems">
          <!---Item Sub Header -->
          <LcFullVerticalSidebarNavGroup :item="item" v-if="item.header" :key="item.title"/>
          <!---If Has Child -->
          <LcFullVerticalSidebarNavCollapse class="leftPadding" :item="item" :level="0" v-else-if="item.children"/>
          <!---Single Item-->
          <LcFullVerticalSidebarNavItem :item="item" v-else class="leftPadding"/>
          <!---End Single Item-->
        </template>
      </v-list>
      <div class="pa-6 userbottom">
        <LcFullVerticalSidebarProfile/>
      </div>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
